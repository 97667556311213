<template>
  <div
    class="login-background"
    :style="{ backgroundImage: `url(${getBackgroundImage})` }"
  >
    <v-container class="my-auto fill-width">
      <div class="mx-auto login">
        <div class="logo">
          <img :src="getLogoImage" alt="" />
        </div>
        <form v-if="!credenciais.confirmar_telefone" @submit.prevent="login">
          <v-text-field
            prepend-icon="mdi-account"
            label="Usuário"
            type="text"
            v-model="credenciais.email"
            required
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-key"
            :append-icon="typeInput ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (typeInput = !typeInput)"
            :type="typeInput ? 'password' : 'text'"
            label="Senha"
            required
            v-model="credenciais.senha"
            autocomplete="on"
          ></v-text-field>
          <v-btn color="primary" type="submit" style="width: 100%">Logar</v-btn>
        </form>
        <form v-else @submit.prevent="login">
          <div class="text-center">
            <p class="text-title">Validação de telefone</p>
            <span class="text-subtitle-1" >Informe o código recebido via SMS no número ** *****-{{getUltimosNumeroTelefone}} </span>
          </div>
          <v-text-field
            prepend-icon="mdi-key"
            :append-icon="typeInput ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (typeInput = !typeInput)"
            :type="typeInput ? 'password' : 'text'"
            label="CODIGO"
            required
            v-model="credenciais.codigo_2fa_telefone"
            autocomplete="off"
          ></v-text-field>
          <v-btn color="primary" type="submit" style="width: 100%">Confirmar</v-btn>
        </form>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {

  data() {
    return {
      typeInput: "password",
      credenciais: {
        email: null,
        senha: null,
        telefone: null,
        codigo_2fa_telefone: null,
        confirmar_telefone: false
      }
    };
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    login() {

      this.credenciais.email = this.credenciais.email.trim();
      this.credenciais.senha = this.credenciais.senha.trim();

      this.$axios
        .post("usuario/autenticar", this.credenciais, {
          headers: {
            "X-Credential-Type": "authenticate"
          },
        })
        .then((response) => {
          this.credenciais.telefone = response.data.telefone;
          this.credenciais.confirmar_telefone = response.data.confirmar_telefone;

          if(this.credenciais.confirmar_telefone){
            return;
          }
          const obj = response.data;
          obj.redirect = !this.credenciais.confirmar_telefone;
          this.$store.commit("login", obj, true);
        }).catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel autenticar",
            text: error.response.data,
          });
        });
    },
  },
  computed: {
    ...mapState(["config"]),
    getLogoImage() {
      if (!this.config.images.logo) return;
      return this.config.images.logo;
    },
    getBackgroundImage() {
      if (!this.config.images.background) return;
      return this.config.images.background;
    },
    getUltimosNumeroTelefone(){
      if(!this.credenciais.telefone) return "";
      return this.$masks.telefone(this.credenciais.telefone).split("-")[1];
    }
  },
  beforeMount(){
    this.credenciais = {
        email: null,
        senha: null,
        telefone: null,
        codigo_2fa_telefone: null,
        confirmar_telefone: false
      }
  }

};
</script>

<style>
.logo {
  position: relative;
  width: 200px;
  height: 80px;

  margin: 0 auto;
}

.logo img {
  width: 100%;
  height: auto;
}
.login {
  max-width: 400px;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
}
.loginLayout {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
}

.login-background {
  overflow: hidden;
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
